var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-card-body', [_c('h2', [_vm._v("Legacy Database Reporting")]), _c('b-row', [_c('b-col', [_c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/home.php"
    }
  }, [_vm._v("Home")])]), _c('li', [_c('a', [_vm._v("Inventory")])]), _c('ul', [_c('li', [_c('a', [_vm._v("Order Items")])]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/order.php"
    }
  }, [_vm._v("Enter PO")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/order_home.php"
    }
  }, [_vm._v("Edit PO / Certificates")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/inventory.php"
    }
  }, [_vm._v("Receive Items")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/change_inventory.php"
    }
  }, [_vm._v("Modify Items Location")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/items.php"
    }
  }, [_vm._v("Add New Items / Show List")])])]), _c('li', [_c('a', [_vm._v("Manage Inventory")])]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/purchases_query.php"
    }
  }, [_vm._v("All Purchases")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/purchases_query_active.php"
    }
  }, [_vm._v("Active Purchases")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/purchases_by_no.php"
    }
  }, [_vm._v("Look for Purchase Number")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/purchases_query_error.php"
    }
  }, [_vm._v("Error Amounts")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/loss.php"
    }
  }, [_vm._v("Report Item Loss")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/bottling_addpackaging.php"
    }
  }, [_vm._v("Add Packaging")])])]), _c('li', [_c('a', [_vm._v("Report")])]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/items_low_stock_query.php"
    }
  }, [_vm._v("Low Stock Items")])]), _c('li', [_c('a', [_vm._v("Out of Stock Items")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/purchases_query_undelivered.php"
    }
  }, [_vm._v("Items in Transit")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/purchases_query_overdue.php"
    }
  }, [_vm._v("Overdue Items")])])])]), _c('li', [_c('a', [_vm._v("Production")])]), _c('ul', [_c('li', [_c('a', [_vm._v("Mashing & Distillation")]), _vm._v(" ")]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/product.php"
    }
  }, [_vm._v("Start Product")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/mashing_history1.php"
    }
  }, [_vm._v("Add Items to Mash")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/fermentation_details.php"
    }
  }, [_vm._v("Transfer to Fermenter")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/mashing_history_brix.php"
    }
  }, [_vm._v("Brix Measurement")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/distillation_details.php"
    }
  }, [_vm._v("Distillations")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/distillation_to_storage_container.php"
    }
  }, [_vm._v("Distilaltion to Container")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/distillation_to_storage_container_transfer.php"
    }
  }, [_vm._v("Transfer Container")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/raw_materials.php"
    }
  }, [_vm._v("Add a new Product")])])]), _c('li', [_c('a', [_vm._v("Alcohol Finishing")])]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/distillation_to_finished.php"
    }
  }, [_vm._v("Start Finished Product")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/alcohol_finishing_history.php"
    }
  }, [_vm._v("Add Items to Finish Product")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/alcohol_finishing_barrel.php"
    }
  }, [_vm._v("Move to Barrel")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/alcohol_finishing_barrel_second.php"
    }
  }, [_vm._v("Reuse Barrel")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/barrel_code_public.php"
    }
  }, [_vm._v("Calculate Barrel")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/alcohol_finishing_bottle.php"
    }
  }, [_vm._v("Bottle")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/alcohol_finishing_bulk.php"
    }
  }, [_vm._v("Bulk Bottle")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/contract_bottle.php"
    }
  }, [_vm._v("Contract Bottle")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/bottling_addpackaging.php"
    }
  }, [_vm._v("Add Packaging to Bottling")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/finished_product_details.php"
    }
  }, [_vm._v("Add Finished Product")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/upc_list.php"
    }
  }, [_vm._v("Manage Product List")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/upc_list_view.php"
    }
  }, [_vm._v("View Product List")])])]), _c('li', [_c('a', [_vm._v("Report")])]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_mashing_history.php"
    }
  }, [_vm._v("Show Mashes to Distill")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_products_to_bottle.php"
    }
  }, [_vm._v("Show Products to Bottle")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_products_to_barrel.php"
    }
  }, [_vm._v("Show Products to Barrel")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_products_to_finish.php"
    }
  }, [_vm._v("Show Products to Finish")])])])]), _c('li', [_c('a', [_vm._v("Ship & Invoice")])]), _c('ul', [_c('li', [_c('a', [_vm._v("Warehouse")])]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/prod_avail_warehouse_query.php"
    }
  }, [_vm._v("Show Warehouse Inventory")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/prod_avail_warehouse_error_query.php"
    }
  }, [_vm._v("Show Warehouse Errors")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/warehouse_loss.php"
    }
  }, [_vm._v("Fix Warehouse Errors")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/warehouse_return.php"
    }
  }, [_vm._v("Receive Product Return")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/warehouse_transfer.php"
    }
  }, [_vm._v("Transfer Product")])])]), _c('li', [_c('a', [_vm._v("Orders")])]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/orderinc_create.php"
    }
  }, [_vm._v("Enter Inc Order")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/orderinc_manage.php"
    }
  }, [_vm._v("Manage Inc Orders")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/orderinc_openorders.php"
    }
  }, [_vm._v("Open Inc Orders")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/orderinc_recentorders.php"
    }
  }, [_vm._v("Recent Orders")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/orderinc_view.php"
    }
  }, [_vm._v("Review Orders")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/orderinc_productneed.php"
    }
  }, [_vm._v("Product Need")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/orderinc_orderconf.php"
    }
  }, [_vm._v("Send Order Confirmation")])])]), _c('li', [_c('a', [_vm._v("Picklists")])]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/create_picklist.php"
    }
  }, [_vm._v("Enter Picklist")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/picklist_add_items.php"
    }
  }, [_vm._v("Add Items to Picklist")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/picklist_openorders.php"
    }
  }, [_vm._v("Open Picklists")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/picklist_recentorders.php"
    }
  }, [_vm._v("Recent Picklists")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/picklist_review.php"
    }
  }, [_vm._v("Review Picklists")])])]), _c('li', [_c('a', [_vm._v("Invoices")])]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/invoices.php"
    }
  }, [_vm._v("Enter Invoice")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/invoice_orders.php"
    }
  }, [_vm._v("Add Items to Invoice")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/invoice_send.php"
    }
  }, [_vm._v("Send Invoice")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/invoice_openorders.php"
    }
  }, [_vm._v("Open Invoices")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/invoice_recentorders.php"
    }
  }, [_vm._v("Recent Invoices")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/review_invoices.php"
    }
  }, [_vm._v("QB Review Invoices")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/review_invoices_payment.php"
    }
  }, [_vm._v("QB Review Payment")]), _vm._v(" ")])]), _c('li', [_c('a', [_vm._v("Report")])]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_invoices.php"
    }
  }, [_vm._v("Product Sold")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_invoices_detail.php"
    }
  }, [_vm._v("Invoice Details")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_invoices_by_customer.php"
    }
  }, [_vm._v("Invoices by Customer")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_invoices_by_month.php"
    }
  }, [_vm._v("Invoices by Month")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_invoices_by_quarter.php"
    }
  }, [_vm._v("Invoices by Quarter")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_invoices_by_year.php"
    }
  }, [_vm._v("Invoices by Year")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_invoices_by_region.php"
    }
  }, [_vm._v("Invoices by Region")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_invoices_by_product.php"
    }
  }, [_vm._v("Invoices by Product")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_invoices_by_customeryear.php"
    }
  }, [_vm._v("Invoices by Customer / Year")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_invoices_by_customertime.php"
    }
  }, [_vm._v("Invoices by Customer / Period")])])])]), _c('li', [_c('a', [_vm._v("Reporting")])]), _c('ul', [_c('li', [_c('a', [_vm._v("TTB Reporting")])]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_losses.php"
    }
  }, [_vm._v("Heads Dumped and Losses")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_grain_used.php"
    }
  }, [_vm._v("Grain Used")])])]), _c('li', [_c('a', [_vm._v("General Reporting")])]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_mashing_schedule.php"
    }
  }, [_vm._v("Mashing Schedule")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_mashing_distilling_schedule.php"
    }
  }, [_vm._v("Mashing / Distilling Schedule")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_fermentation_schedule.php"
    }
  }, [_vm._v("Fermentation Schedule")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_distillation_schedule.php"
    }
  }, [_vm._v("Distillation Schedule")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_distillation_container_schedule.php"
    }
  }, [_vm._v("Distillation Containers Schedule")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_alcohol_finishing_schedule.php"
    }
  }, [_vm._v("AF Schedule")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_bottling_schedule.php"
    }
  }, [_vm._v("Bottling Schedule")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_barreling_schedule.php"
    }
  }, [_vm._v("Barreling Schedule")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/barrel_reserve.php"
    }
  }, [_vm._v("Reserve / Unreserve Barrels")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/barrel_reserve_list.php"
    }
  }, [_vm._v("Reserved Barrels Schedule")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_picklist_schedule.php"
    }
  }, [_vm._v("Picklist Schedule")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_flow.php"
    }
  }, [_vm._v("Related Flow")])])])]), _c('li', [_c('a', [_vm._v("QR Details")])]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/purchases_by_no.php"
    }
  }, [_vm._v("Purchases Detail")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_mashing_details.php"
    }
  }, [_vm._v("Mash Detail")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_fermentation_details.php"
    }
  }, [_vm._v("Fermentation Detail")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_distillation_details.php"
    }
  }, [_vm._v("Distillation Detail")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_distillation_container_details.php"
    }
  }, [_vm._v("Distillation Container Detail")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_alcohol_finishing_details.php"
    }
  }, [_vm._v("AF Details")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_barrel_details.php"
    }
  }, [_vm._v("Barrel Detail")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_barrel_details_qr.php"
    }
  }, [_vm._v("Barrel QR Generator")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_bottling_details.php"
    }
  }, [_vm._v("Bottling Detail")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_picklist_details.php"
    }
  }, [_vm._v("Picklist Detail")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/rep_invoices_detail.php"
    }
  }, [_vm._v("Invoice Detail")])])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/upload_old.php"
    }
  }, [_vm._v("Files")])]), _c('li', [_c('a', [_vm._v("Barrel Coding")])]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/barrel_code.php"
    }
  }, [_vm._v("Barrel Code Creator")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/barrel_export.php"
    }
  }, [_vm._v("Export Code Creator")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/database/barrel_export_list.php"
    }
  }, [_vm._v("Export List")])])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/login/logout.php"
    }
  }, [_vm._v("Logout")])]), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/start.php"
    }
  }, [_vm._v("Main Navigation")])])])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }